<template>
  <div class="service-times-presentations template-2">
    <header class="page-header container mx-lg">
      <h1>{{ translations.tcMemberRoles }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </header>
    <!-- / Page Header -->

    <data-table
      :fields="dataFieldsCommittee"
      :items="memberCommitteeAndRoles.committee"
      :i18n="translations.components"
      :hiddenItems="hiddenItems"
      :noRecordsMessage="translations.tcNoCommitteePositionsFound"
      :title="translations.tcCommitteePositions"
      :toggle="true"
      class="presentations mb-4"
    ></data-table>

    <data-table
      :fields="dataFieldsRoles"
      :items="memberCommitteeAndRoles.roles"
      :i18n="translations.components"
      :hiddenItems="hiddenItems"
      :noRecordsMessage="translations.tcNoWebsiteSecurityRolesFound"
      :title="translations.tcWebsiteSecurityRoles"
      :toggle="true"
      class="mt-4"
    ></data-table>

    <page-footer class="mx-lg mt-5">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import DataTable from '@/components/DataTableMembers.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'member-roles',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/mb/member-profile',
        location: '',
      },
      hiddenItems: {
        display: false,
      },
      dataFieldsCommittee: [
        { key: 'committee_name', label: 'Committee', sortable: true },
        { key: 'office', label: 'Office', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
      ],
      dataFieldsRoles: [
        { key: 'role_name', label: 'Security Role', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
      ],
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      getMemberCommitteeAndRoles: 'membership/getMemberCommitteeAndRoles',
    }),
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadIndividualProfile(),
        await this.getMemberCommitteeAndRoles(this.userSelectedIndividualKey),
      ]).then(() => {
        ;(this.backJumpObj.location = this.individualProfile.formal_name), this.setLoadingStatus(false)
      })
    },
    loadTranslations() {
      this.dataFieldsCommittee[0].label = this.translations.tcCommittee
      this.dataFieldsCommittee[1].label = this.translations.tcOffice
      this.dataFieldsCommittee[2].label = this.translations.tcStartDate
      this.dataFieldsRoles[0].label = this.translations.tcSecurityRole
      this.dataFieldsRoles[1].label = this.translations.tcStartDate
    },
    translateMemberCommitteeAndRoles () {
      if (!this.translations.common || !this.memberCommitteeAndRoles) return false
      // Committee Positions
      const translatedCommitteePositions = this.memberCommitteeAndRoles['committee'].map(position => {
        const committeeGuid = position.ctp_key || ''
        position.office = this.translations.common['committee-positions'].hasOwnProperty(committeeGuid) ?
          this.translations.common['committee-positions'][committeeGuid] :
          position.office
        return position
      })
      // Website Security Roles
      const translatedRoles = this.memberCommitteeAndRoles['roles'].map(role => {
        const roleGuid = role.rle_key || ''
        role.role_name = this.translations.common['roles'].hasOwnProperty(roleGuid) ?
          this.translations.common['roles'][roleGuid] :
          role.role_name
        return role
      })

      this.memberCommitteeAndRoles['committee'] = translatedCommitteePositions
      this.memberCommitteeAndRoles['roles'] = translatedRoles
      return true
    }
  },

  computed: {
    ...mapGetters({
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      individualProfile: 'membership/individualProfile',
      memberCommitteeAndRoles: 'membership/memberCommitteeAndRoles',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  async created() {
    //need a selected individual or redirect back to membership landing
    try {
      this.setLoadingStatus(true)
      if (!this.userSelectedIndividualKey) {
        this.$router.push({ name: 'membership' })
      }
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('back-jump', 'data-table-members'),
        await this.getComponentTranslations('common.committee-positions', 'common.roles'),
        await this.pageLoad(),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)

        this.translateMemberCommitteeAndRoles()
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    BackJump: BackJump,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    iTooltip: iTooltip,
    ToggleArrow: ToggleArrow,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }
}

.presentations {
  .g-table {
    td,
    th {
      &:nth-of-type(1) {
        width: 200px;
      }
      &:nth-of-type(2) {
        width: 350px;
      }
      &:nth-of-type(3) {
        width: 125px;
      }
      &:nth-of-type(4) {
        width: 325px;
      }
      &:nth-of-type(5) {
        width: auto;
      }
    }
  }
}
</style>
